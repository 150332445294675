.App {
  text-align: center;
}

.App__fab {
  transition: background-color 200ms ease-in-out;
  position: fixed;
  right: 16px;
  top: 16px;
}
