.Beacon {
  position: relative;
  /* background-color: rgba(41, 98, 255, 0.5);
  height: 32px;
  width: 32px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Beacon:before,
.Beacon:after {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  top: 8px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 5px rgba(245, 0, 87, 0.5);
}

.Beacon:before {
  animation: beacon-active 2s infinite linear;
}

.Beacon:after {
  box-shadow: 0px 0px 5px 5px rgba(245, 0, 87, 0.5);
  animation: beacon-active 2s infinite linear 500ms;
}

@keyframes beacon-active {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
